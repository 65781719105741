<template>
    <div
        class="message-panel"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
    >
        <div class="white-bg">
            <h3
                class="g-title"
                style="margin-top: -20px;"
            >
                消息中心
                <span class="msg-total">
                    (共{{total}}条消息，
                    <em>未读消息为{{unread}}条</em>)
                </span>
            </h3>
            <div class="message-hd">
                <el-button @click="sendMsg">
                    <i class="jk-icon-fasongxiaoxi"></i>发送消息
                </el-button>

                <el-button
                    @click="messageBox(1)"
                    :class="{'messageactive':active==1}"
                >发件箱</el-button>

                <el-button
                    @click="messageBox(2)"
                    :class="{'messageactive':active==2}"
                >收件箱</el-button>

                <el-button
                    @click="messageBox(3)"
                    :class="{'messageactive':active==3}"
                >未读消息</el-button>

                <el-form>
                    <el-form-item>
                        <el-input
                            placeholder="搜索用户名称、极客ID、手机"
                            v-model="searchKeyWord"
                        ></el-input>
                        <el-button
                            type="primary"
                            @click="search"
                        >搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="message-bd">
                <el-table
                    ref="multipleTable"
                    border
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="40"
                    ></el-table-column>
                    <el-table-column label="内容">

                        <template slot-scope="props">
                            <div class="msg-source">
                                <el-badge v-if="user_id == props.row.receive_id && props.row.status == 2">
                                    <!--接收未读-->
                                    <img :src="$img.msg_icon1" />
                                </el-badge>
                                <el-badge v-if="user_id == props.row.receive_id && props.row.status == 3">
                                    <img :src="$img.msg_icon2" />
                                    <!--接收已读-->
                                </el-badge>
                                <el-badge v-if="user_id == props.row.send_id">
                                    <img :src="$img.msg_icon3" />
                                    <!--发给别人的-->
                                </el-badge>
                                <p class="source">
                                    <span class="sender">{{ user_id == props.row.send_id?'您':props.row.send_name }}</span> 发给
                                    <span>{{ user_id == props.row.receive_id?'您':props.row.receive_name }}</span> 的信息
                                </p>
                            </div>
                            <div class="task-cont">
                                <h4 class="name">{{props.row.title}}</h4>
                                <p
                                    style="font-size:12px;"
                                    class="msg-desc"
                                    v-html="props.row.content"
                                ></p>
                            </div>
                        </template>

                    </el-table-column>

                    <el-table-column
                        label="状态"
                        width="50"
                    >
                        <template slot-scope="scope">
                            <span
                                class="blue"
                                style="font-size:12px;"
                            >{{ scope.row.status!=3?"未读":"" }}</span>
                            <span
                                class="red"
                                style="font-size:12px;"
                            >{{ scope.row.status==3?"已读":"" }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="send_time"
                        label="时间"
                        width="160"
                    ></el-table-column>

                    <el-table-column
                        label="操作"
                        width="110"
                    >
                        <template slot-scope="props">
                            <!-- <el-button
                                :class="{'lookColor':props.row.msgCode==1,'attestColor':props.row.msgCode==2,'buyColor':props.row.msgCode==3,'regColor':props.row.msgCode==4}"
                            ></el-button>-->
                            <!-- <el-button class="yellow" v-if="user_id == 0">去认证</el-button> -->
                            <!--未认证 -->
                            <!-- <el-button
                                class="color-main"
                                style="border:1px solid #74A5F7;padding:1px 4px;border-radius:3px;"
                                v-if="user_id == props.row.send_id || props.row.is_pay == 1 "
                                @click="lookDetail(props.row.id)"
                            >查看</el-button>
                            <el-button
                                class="color-main"
                                style="border:1px solid #74A5F7;padding:1px 4px;border-radius:3px;"
                                v-if="props.row.send_id==0"
                                @click="lookDetail(props.row.id)"
                            >查看</el-button>-->
                            <!--已认证 已支付 -->
                            <!-- <el-button
                            class="yellow"
                            style="border:1px solid #FA6429;padding:1px 4px;border-radius:3px;"
                            v-if="user_id != props.row.send_id && props.row.is_pay != 1 && props.row.send_id !=0 "
                            @click="showPay(props.row.id)"
                            >支付查看内容</el-button>-->
                            <!--已认证 未支付 -->

                            <!--20100906修改，无需付费-->
                            <el-button
                                class="color-main"
                                style="border:1px solid #74A5F7;padding:1px 4px;border-radius:3px;"
                                @click="lookDetail(props.row.id)"
                            >查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-row class="opera-wrap">
                    <el-col :span="10">
                        <!-- <el-checkbox @click="selectAll">全选</el-checkbox> -->
                        <el-button @click="del">删除</el-button>
                        <el-button @click="clear">清空所有</el-button>
                        <el-button @click="isRead">标记为已读</el-button>
                        <el-button @click="reportBtn">举报</el-button>
                    </el-col>
                    <el-dialog
                        class="blue-top-border"
                        title="举报成功"
                        :visible.sync="SuccessTips"
                        width="400px"
                        center
                        append-to-body
                        @close="closeall"
                        :close-on-click-modal="false"
                    >
                        <div class="pointCoupon-buy">
                            <h5>举报成功，我们将会对该账号进行严格审查，感谢您对我们的工作支持和帮助。</h5>
                        </div>
                        <span
                            slot="footer"
                            class="dialog-footer"
                        >
                            <el-button @click="SuccessTips = false">返回</el-button>
                        </span>
                    </el-dialog>
                    <el-col :span="12">
                        <pagination
                            style="text-align:right;"
                            :pageSize="page.size"
                            :currentPage="page.index"
                            :pageTotal="page.total"
                            @current-change="page_change"
                        ></pagination>
                    </el-col>
                </el-row>

                <!-- ===============================支付选择对话框=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="支付"
                    :visible.sync="pointCoupon"
                    width="500px"
                    center
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="pointCoupon-buy">
                        <p>
                            <span class="yellow">支付后可查看全部内容</span>
                        </p>
                        <p>
                            <span class="sleft">点券余额：</span>
                            <span class="sright">{{userInfo.info.points_surplus}}点券</span>
                        </p>
                        <p>
                            <span class="sleft">本次消耗：</span>
                            <span class="sright">{{needpoint}}点券</span>
                        </p>
                        <span
                            class="recharge"
                            v-if="userInfo.info.points_surplus < needpoint"
                            @click="wallet"
                        >点券不够？去充值</span>
                        <el-button
                            type="primary"
                            @click="payClick(2)"
                            v-if="userInfo.info.points_surplus > needpoint"
                        >点券支付</el-button>
                        <div class="other-buy">
                            <h5>其他支付方式</h5>
                            <ol class="buy-way">
                                <li
                                    @click="payClick(1)"
                                    v-if="userInfo.info.surplus > needmoney"
                                >
                                    <span class="buy-icon">
                                        <i class="jk-icon-yuezhifu"></i>
                                    </span>
                                    <strong>余额支付</strong>
                                </li>
                                <li @click="payClick(3)">
                                    <span class="buy-icon">
                                        <i class="jk-icon-weixinzhifu"></i>
                                    </span>
                                    <strong>微信支付</strong>
                                </li>
                                <li @click="payClick(4)">
                                    <span class="buy-icon">
                                        <i class="jk-icon-zhifubaozhifu"></i>
                                    </span>
                                    <strong>支付宝支付</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================微信支付二维码=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="微信支付"
                    :visible.sync="wxBuyBtn"
                    width="500px"
                    center
                    append-to-body
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="pointCoupon-buy">
                        <p>支付金额</p>
                        <p class="price">
                            ￥
                            <strong v-text="price"></strong>（本次）
                        </p>
                        <div class="buy-ewm">
                            <img :src="pay_image" />
                        </div>
                        <div class="guide">
                            请使用微信扫描
                            <br />二维码完成支付
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================微信支付二维码=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="支付宝支付"
                    :visible.sync="zfbBuyBtn"
                    width="500px"
                    center
                    append-to-body
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="pointCoupon-buy">
                        <p>支付金额</p>
                        <p class="price">
                            ￥
                            <strong v-text="price"></strong>（本次）
                        </p>
                        <div class="buy-ewm">
                            <img :src="pay_image" />
                        </div>
                        <div class="guide">
                            请使用支付宝扫描
                            <br />二维码完成支付
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================支付成功提示框=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="支付成功"
                    :visible.sync="SuccessTips"
                    width="400px"
                    center
                    append-to-body
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="pointCoupon-buy">
                        <h5>支付成功，现在可以直接查看消息内容。</h5>
                    </div>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button @click="lookDetail(id)">查看内容</el-button>
                    </span>
                </el-dialog>

                <!-- ===============================余额支付弹出框=================================  -->
                <el-dialog
                    class="blue-top-border subs-dialog subys-dialog"
                    title="余额支付"
                    :visible.sync="yeBuyBtn"
                    width="400px"
                    center
                    append-to-body
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="notprov-box">
                        <ul class="hosting-list hostings-list">
                            <li class="hosting-item apl-item">
                                <span>支付金额</span>
                                <i class="warn">{{needmoney}}元</i>
                            </li>
                            <li class="hosting-item">
                                <span>支付密码</span>
                            </li>
                            <li class="hosting-item">
                                <el-input
                                    v-model="pay_password"
                                    type="password"
                                    placeholder="请输入支付密码完成支付"
                                ></el-input>
                            </li>
                        </ul>
                    </div>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button
                            type="primary"
                            @click="doPay"
                        >确认支付</el-button>
                    </span>
                </el-dialog>

                <!-- ===============================点券支付弹出框=================================  -->
                <el-dialog
                    class="blue-top-border subs-dialog subys-dialog"
                    title="点券支付"
                    :visible="dqBuyBtn"
                    width="400px"
                    center
                    append-to-body
                    @close="closeall"
                    :close-on-click-modal="false"
                >
                    <div class="notprov-box">
                        <ul class="hosting-list hostings-list">
                            <li class="hosting-item apl-item">
                                <span>支付点券</span>
                                <i class="warn">{{needpoint}}点</i>
                            </li>
                            <li class="hosting-item">
                                <span>支付密码</span>
                            </li>
                            <li class="hosting-item">
                                <el-input
                                    v-model="pay_password"
                                    type="password"
                                    placeholder="请输入支付密码完成支付"
                                ></el-input>
                            </li>
                        </ul>
                    </div>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button
                            type="primary"
                            @click="doPay"
                        >确认支付</el-button>
                    </span>
                </el-dialog>

                <el-dialog
                    title="举报内容"
                    :visible.sync="reportDialog"
                    width="40%"
                    :close-on-click-modal="false"
                >
                    <el-input
                        v-model="reportContent"
                        autocomplete="off"
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 6}"
                    ></el-input>
                    <div
                        slot="footer"
                        class="dialog-footer"
                        style="text-align:center;"
                    >
                        <el-button
                            type="primary"
                            @click="report"
                        >提 交</el-button>
                    </div>
                </el-dialog>
            </div>

        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "index",
    metaInfo() {
        return {
            title: "消息中心 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        Pagination //分页
    },
    data() {
        return {
            page: {
                index: 1,
                size: 10,
                total: 0
            },
            id: 0, //支付的消息id
            order_sn: "",
            user_id: 0,
            unread: 0,
            total: 0,
            pay_image: "",
            checked: false,
            pointCoupon: false,
            wxBuyBtn: false,
            zfbBuyBtn: false,
            yeBuyBtn: false, //余额支付弹出框
            dqBuyBtn: false, //点券支付弹出框
            SuccessTips: false,
            pay_type: 0,
            tableData: [],
            needmoney: 0,
            needpoint: 0,
            loading: false,
            multipleSelection: [],
            pay_password: "", //支付密码
            price: 0, //二维码支付价格
            timer: "", //定时器
            searchKeyWord: "", //搜索关键字
            messageType: 0, //收件箱和发件箱切换（0:全部；1:发；2:收）
            active: 0,
            ids: [], //信息选中项
            reportContent: "", //举报内容
            reportDialog: false //举报消息对话框
        };
    },

    created() {
        //获取url过来的分页
        if (this.$route.query.page) {
            this.page.index = parseInt(this.$route.query.page);
        }
        //获取url过来的消息分类（1:发；2:收；3:未读）
        if (this.$route.query.id) {
            this.active = this.messageType = parseInt(this.$route.query.id);
            this.loadListData();
        }
    },

    computed: {
        ...mapState(["userInfo"])
    },

    methods: {
        //标记消息图标为已读
        ...mapActions("userInfo", ["ajaxUserInfo"]),
        //获取消息列表
        loadListData() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/message/message/lists",
                {
                    page: _this.page.index,
                    limit: _this.page.size,
                    keyword: _this.searchKeyWord,
                    message_type: _this.messageType
                },
                data => {
                    _this.loading = false;
                    if (data.code == 200) {
                        _this.unread = data.data.unread; //未读数
                        _this.total = data.data.total; //总数
                        _this.page.total = data.data.total; //分页总数
                        _this.tableData = data.data.list; //消息列表
                        _this.needmoney = data.data.read_payMoney; //消息需要支付金额
                        _this.needpoint = data.data.read_payPoint; //消息需要支付点券
                        _this.user_id = data.data.user_id; //用户id
                        _this.$parent.changeMsg(_this.$parent.menuData);
                    } else {
                        _this.$message.error("数据加载出错");
                    }
                }
            );
        },
        //跳转到充值页面
        wallet() {
            this.$router.push("/user/wallet/list");
        },
        //分页
        page_change(index) {
            this.page.index = index;
            sessionStorage.setItem("messagePage", index);
            this.loadListData();
        },

        //查看消息内容
        lookDetail(id = 0) {
            this.$router.push("/user/message/detail?id=" + id);
        },
        //发送消息按钮
        sendMsg() {
            sessionStorage.setItem('currentPageUrl', '/user/message?id=1');
            this.$router.push("/user/message/sendmsg");
        },
        //选择支付按钮事件
        payClick(type) {
            this.closeall(); //关闭所有窗口;
            this.pay(type); //执行支付
        },
        SuccessTipsClick() {
            (this.SuccessTips = true), (this.pointCoupon = false);
        },
        //生成查看消息订单
        pay(type) {
            let _this = this;
            _this.pay_type = type;
            _this.post(
                "/message/message/read",
                {
                    message_id: _this.id,
                    pay_type: type
                },
                res => {
                    if (res.code == 200) {
                        _this.order_sn = res.data.order_no;
                        switch (type) {
                            case 1: //余额支付
                                _this.yeBuyBtn = true;
                                break;
                            case 2: //点券支付
                                _this.dqBuyBtn = true;
                                break;
                            case 3: //微信支付
                                _this.doPay();
                                break;
                            case 4: //支付宝支付
                                _this.doPay();
                                break;
                        }
                    } else {
                        _this.$message.error(data.msg);
                    }
                }
            );
        },
        doPay() {
            var _this = this;
            _this.pay_image = "";
            if (
                (_this.pay_type == 1 || _this.pay_type == 2) &&
                _this.pay_password == ""
            ) {
                _this.$message.error("支付密码不能为空");
                return false;
            }
            _this.loading = true;
            _this.post(
                "/pay/index/pay",
                {
                    order_no: _this.order_sn,
                    password: _this.pay_password
                },
                res => {
                    _this.loading = false;
                    if (res.code == 200) {
                        switch (_this.pay_type) {
                            case 1:
                                _this.yeBuyBtn = false;
                                _this.SuccessTipsClick();
                                break;
                            case 2:
                                _this.dqBuyBtn = false;
                                _this.SuccessTipsClick();
                                break;
                            case 3:
                                _this.wxBuyBtn = true;
                                _this.price = res.data.price;
                                _this.pay_image = res.data.img;
                                _this.timer = setInterval(() => {
                                    _this.isPayCheck();
                                }, 2000);
                                break;
                            case 4:
                                _this.zfbBuyBtn = true;
                                _this.price = res.data.price;
                                _this.pay_image = res.data.img;
                                _this.timer = setInterval(() => {
                                    _this.isPayCheck();
                                }, 2000);
                                break;
                        }
                    } else {
                        _this.$message.error(res.msg);
                        return false;
                    }
                }
            );
        },
        //支付查看全部内容
        showPay(id) {
            this.id = id;
            this.pointCoupon = true; //支付选择弹出框
        },
        //关闭所有窗口
        closeall() {
            this.pay_password = "";
            this.pointCoupon = false; //关闭支付选择对话框
            this.wxBuyBtn = false; //关闭微信支付对话框
            this.zfbBuyBtn = false; //关闭支付宝支付对话框
            this.dqBuyBtn = false; //关闭点券支付对话框
            this.yeBuyBtn = false; //关闭余额支付对话框
            clearInterval(this.timer); //清除定时请求支付成功定时器
        },
        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/index/is_pay",
                {
                    order_no: _this.order_sn
                },
                function (res) {
                    if (res.code == 200) {
                        _this.closeall(); //关闭对话框
                        _this.lookDetail(_this.id); //查看消息详情
                    }
                }
            );
        },
        //选择消息checkbox
        handleSelectionChange(val) {
            this.ids = [];
            val.forEach(element => {
                this.ids.push(element.id);
            });
        },
        //搜索
        search() {
            this.loadListData();
        },
        //收发邮件箱
        messageBox(e) {
            this.page.index = 1; //切换分页到第一页
            sessionStorage.setItem("messageActive", e); //缓存消息类型，用于历史记录
            this.active = this.messageType = e; //选中项和消息类型（1:发；2:收；3:未读）
            this.loadListData();
        },
        //删除信息
        del() {
            let _this = this;
            if (_this.ids == "") {
                _this.$message.error("请选择删除项");
                return false;
            }
            this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.post(
                        "/message/message/del",
                        {
                            ids: _this.ids
                        },
                        function (res) {
                            if (res.code == 200) {
                                _this.$message.success(res.msg);
                                _this.loadListData();
                                _this.ajaxUserInfo();//刷新用户数据
                            } else {
                                _this.$message.error(res.msg);
                            }
                        }
                    );
                })
                .catch(() => { });
        },
        //标记已读信息
        isRead() {
            let _this = this;
            if (_this.ids == "") {
                _this.$message.error("请选择标记项");
                return false;
            }
            this.post(
                "/message/message/is_read",
                {
                    ids: _this.ids
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success(res.msg);
                        _this.ajaxUserInfo();//刷新用户数据
                        _this.loadListData();
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },
        //清空全部
        clear() {
            let _this = this;
            this.$confirm("是否永久清空所有消息, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.post(
                        "/message/message/clear",
                        {
                            message_type: _this.messageType,
                            ids: _this.ids
                        },
                        function (res) {
                            if (res.code == 200) {
                                _this.$message.success(res.msg);
                                _this.loadListData();
                                _this.ajaxUserInfo();//刷新用户数据
                            } else {
                                _this.$message.error(res.msg);
                            }
                        }
                    );
                })
                .catch(() => { });
        },
        //举报按钮
        reportBtn() {
            let _this = this;
            _this.reportContent = "";
            if (_this.ids == "") {
                _this.$message.error("请选择要举报的消息");
                return false;
            }
            this.reportDialog = true;
        },
        //举报
        report() {
            let _this = this;
            this.post(
                "/message/message/is_report",
                {
                    ids: _this.ids,
                    report_content: _this.reportContent
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success(res.msg);
                        _this.reportDialog = false;
                        _this.loadListData();
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/message.less";

.pointCoupon-buy {
    .el-button {
        margin-top: 20px !important;
    }
    p {
        width: 100% !important;
        display: block !important;
        height: 30px !important;
        line-height: 30px;
    }
}
.sleft {
    display: block;
    width: 50%;
    float: left;
    text-align: right;
}
.sright {
    display: block;
    width: 50%;
    float: left;
    text-align: left;
}
.messageactive {
    color: #fff !important;
    background: #587ddc !important;
}
.el-table__empty-text {
    height: 260px;
    line-height: 200px;
}
.msg-total {
    float: right;
    padding-top: 10px;
    padding-right: 20px;
}
.el-table--border td {
    border-right: none;
}
.message-panel .el-table {
    border: none;
}
.el-table--border::after,
.el-table--group::after {
    background: none;
}
</style>


